import React from 'react';
import { useTranslation } from 'react-i18next';
import logo1 from '../../img/baner.jpg'; 
import freeDelivery from '../../components/Baner/img/freeDelivery.png';
import privat from '../../components/Baner/img/privat.png';
import mono from '../../components/Baner/img/mono.png';
import './Baner.css';
import DiscountBanner from './DiscountBanner';

export default function Baner() {
  const { t } = useTranslation();

  return (
    <div className="baner">
      <img src={logo1} alt="Baner" className="baner-img" />
      <div className="baner-text">
        {t('banerText')}
      </div>
      <div className="banner-info">
        <div className="delivery-info">
          <span>
            <span className="highlight-delivery">{t('freeDeliveryTextPart1')}</span> 
          </span>
          <img src={freeDelivery} alt="Free Delivery" className="delivery-img" />
        </div>
        <div className="payment-info">
          <img src={privat} alt="Privat" className="payment-img" />
          <img src={mono} alt="Mono" className="payment-img" />
          <span>{t('paymentTextPart1')} <span className="highlight-numbers">3</span>, <span className="highlight-numbers">6</span>, <span className="highlight-numbers">12</span> {t('paymentTextPart2')}</span>
        </div>
      </div>
      <DiscountBanner />
    </div>
  );
}
